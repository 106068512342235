<template>
  <v-container fluid v-if="serviceSelected" class="text-center">
    <h3 v-if="serviceSelected === 1">
      {{ $t("checkout.confirmOrderData.addressSelectedDrive") }}
    </h3>
    <h3 v-if="serviceSelected === 4">
      {{ $t("checkout.confirmOrderData.addressSelectedLocker") }}
    </h3>
    <h3 v-if="serviceSelected === 2">
      {{ $t("checkout.confirmOrderData.addressSelectedDelivery") }}
    </h3>

    <v-card elevation="0" class="confirm-order-body mt-3">
      <h3>{{ cart.shippingAddress.city }}</h3>
      <p>{{ address }}</p>
    </v-card>
    <v-row no-gutters class="mt-4">
      <v-col cols="6" class="pr-2">
        <v-btn
          outlined
          depressed
          large
          block
          class="primary--text text--lighten-1"
          @click="edit"
          >{{ $t("checkout.confirmOrderData.edit") }}</v-btn
        >
      </v-col>
      <v-col cols="6" class="pl-2">
        <v-btn
          depressed
          large
          block
          class="primary lighten-1 white--text"
          @click="submit"
          >{{ $t("checkout.confirmOrderData.confirm") }}</v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped lang="scss">
h3 {
  font-size: 25px;
}
.confirm-order-body {
  background-color: #cddb99;
  padding: 10px;

  h3 {
    font-size: 38px;
    color: #000 !important;
    font-weight: 700;
  }
  p {
    font-size: 25px;
  }
  @media #{map-get($display-breakpoints, 'xs-only')} {
    h3 {
      font-size: 30px;
    }
    p {
      font-size: 18px;
    }
  }
}
</style>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      serviceSelected: null
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    address() {
      let address = this.cart.shippingAddress;
      return `${address.address1}, ${address.addressNumber} - ${address.postalcode} ${address.city} (${address.province})`;
    }
  },
  methods: {
    edit() {
      this.$emit("submit", false);
    },
    submit() {
      this.$emit("submit", true);
    }
  },
  mounted() {
    this.serviceSelected = this.cart.shippingAddress.deliveryServiceId;
  }
};
</script>
