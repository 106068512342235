<template>
  <v-card flat>
    <v-card-title>
      {{ $t("list.addToFavorites") }}
    </v-card-title>
    <v-card-text>
      <v-list>
        <template v-for="(list, index) in whishlistLists">
          <v-list-item :key="list.id" @click="selectList(list.id)">
            <v-list-item-title>
              <v-badge
                color="primary"
                inline
                :content="list.size"
                :value="list.size > 0"
              >
                {{ list.name }}
              </v-badge>
            </v-list-item-title>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-form class="d-flex" @submit.prevent="createAndSelect">
        <v-text-field
          label="Crea una nuova lista"
          hide-details="auto"
          v-model="listName"
          dense
          required
        ></v-text-field>
        <v-btn type="submit" small color="primary">CREA</v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>
<style scoped lang="scss">
.list-selector {
  .v-list {
    height: 400px;
    overflow-y: auto;
  }
  .v-toolbar {
    width: 100%;
    .v-toolbar__content {
      width: 100%;
    }
    .v-btn {
      height: 40px;
    }
  }
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    // height: calc(100vh - 40px);
    .v-list {
      height: calc(100vh - (80px + 56px));
    }
  }
}
</style>
<script>
import list from "~/mixins/list";

export default {
  name: "ListSelector",
  mixins: [list],
  computed: {
    listMaxHeight() {
      return 200 + "px";
    },
    whishlistLists() {
      return this.lists;
    }
  },
  methods: {
    selectList(listId) {
      this.$emit("submit", { listId: listId });
    },
    async createAndSelect() {
      let list = await this.createList();
      this.$emit("submit", { listId: list.id });
    }
  },
  created() {
    this.reload();
  }
};
</script>
