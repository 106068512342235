<template>
  <div v-if="packages && packages.length" class="packages-type-container">
    <template v-if="notFlaggablePackages && notFlaggablePackages.length > 0">
      <div class="d-flex mb-2 mb-sm-0">
        <v-icon color="primary" class="mr-2" large>$package</v-icon>
        <h3>
          Seleziona il tipo di sacchetto che vuoi utilizzare
        </h3>
      </div>
      <small
        class="d-block ml-sm-11 ml-0"
        v-html="$t('cart.checkoutSummary.packageTotalTooltip')"
      ></small>
      <v-radio-group
        class="ml-sm-10 ml-0"
        v-model="packagingTypeId"
        hide-details="true"
      >
        <v-radio
          v-for="pck in notFlaggablePackages"
          :key="pck.productId"
          :value="'' + pck.productId"
          :label="`${pck.name}`"
        >
          <template v-slot:label>
            <!-- <img
            :src="`/img_layout/packaging_type/${pck.productId}.svg`"
            :alt="pck.name"
          /> -->
            <p class="mb-0">
              {{ pck.name }}
              <span
                v-if="pck.shortDescr && pck.shortDescr != ''"
                v-html="pck.shortDescr"
              >
              </span>
              <br />
              <small>{{ pck.description }}</small>
            </p>

            <!-- <br/>-->
          </template>
        </v-radio>
      </v-radio-group>
    </template>
    <template v-if="flaggablePackages && flaggablePackages.length > 0">
      <div v-for="pck in flaggablePackages" :key="pck.productId">
        <!-- <span class="font-weight-bold primary--text text--lighten-1"
          >{{ pck.name }}:</span
        >-->

        <v-checkbox
          class="mt-1"
          v-model="packagingBoxId"
          :label="pck.name + pck.shortDescr"
        ></v-checkbox>
        <span>{{ pck.description }}</span>
      </div>
    </template>
  </div>
</template>
<style lang="scss">
.packages-type-container {
  padding: 12px;
  border: 2px solid var(--v-primary-base);
}
</style>
<script>
import cartService from "~/service/cartService";

import { mapActions } from "vuex";
import { mapCartInfo } from "~/service/ebsn";
// import get from "lodash/get";

export default {
  name: "PackagesTypeList",
  data() {
    return {
      packages: []
      // flaggablePackagesModel: []
    };
  },
  computed: {
    ...mapActions({
      setCartInfo: "cart/setCartInfo"
    }),
    ...mapCartInfo({
      packagingTypeId: "package_type",
      packagingBoxId: "package_box"
    }),
    notFlaggablePackages() {
      return this.packages
        ? this.packages.filter(p => {
            return p.flaggable !== "true";
          })
        : [];
    },
    flaggablePackages() {
      return this.packages
        ? this.packages.filter(p => {
            return p.flaggable === "true";
          })
        : [];
    }
  },
  methods: {
    async fetchPackages() {
      this.packages = await cartService.getPackages();
    }
    // toggleFlaggablePck(propertyName, value) {
    //   let payload = {};
    //   payload[propertyName] = value || null;
    //   this.$store.dispatch("cart/setCartInfo", payload);
    // }
  },
  mounted() {
    this.fetchPackages();
  }
};
</script>
